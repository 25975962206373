import Consultation from "./Consultation/Consultation";
import './Intergation.scss';
import Top from "../../components/Top/Top";
import List from "../../components/List/List";
import offers from "./offers";

const Intergation: React.FC = () => {
    return (
        <section className="intergation">
            <Top 
                header="Интеграция, Кастомизация, Разработка"
                text="Наша команда занимается разработкой программного обеспечения с 2012 года, за это время к нам присоединилось большое количество высококвалифицированных специалистов, с которыми мы можем предложить Вам лучшие программные решения."
                classParent="intergation-top"
                btn="Связаться"
            />
            <List
                data={offers}
                classParent="offer"
            />
            <Consultation />
        </section>
    )
}

export default Intergation;