import List from '../../../components/List/List';
import './Products.scss';
import products from './data';

const Products: React.FC = () => {
    return (
        <section className="solution-products">
            <List
                data={products}
                classParent='solution-products-list'
            />
        </section>
    )
}

export default Products;