import YandexMap from "./Map/YandexMap";
import './Contact.scss';
import Top from "../../components/Top/Top";

const Contact: React.FC = () => {
    return (
        <section className="contact">
            <Top 
                header="Контакты"
                text="Если у Вас есть вопросы или предложения, свяжитесь с нами по координатам, указанным ниже: "
                classParent="contact-top"
                text2="email: info@ommatid.com"
            />
            <YandexMap />
        </section>
    )
}

export default Contact;