import Result from "./Results/Result";
import Top from "../../components/Top/Top";
import './Solutions.scss';
import Experience from "./Experience/Experience";
import List from "../../components/List/List";
import products from "./Products/data";
import Products from "./Products/Products";

const Solutions: React.FC = () => {
    return (
        <section className="solutions">
            <Top 
                header="Наши решения:"
                text="В этом разделе Вы можете познакомится с нашими решениями для различных отраслей бизнеса и производства. Смотрите и выбирайте."
                btn="Контакты"
                classParent="solutions-top"
            />
            <Experience />
            <Products />
            {/* <Result /> */}
        </section>
    )
}

export default Solutions;