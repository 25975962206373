import { Link, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from '../../assets/images/logo.png';
import './Header.scss';
import { useEffect, useState } from "react";

const Header: React.FC = () => {
    const location = useLocation();
    let classname: string = 'header ';
    switch(location.pathname) {
        case '/': classname += 'home'
        break;
        default: classname += 'other'
    }

    const [isOpen, setOpen] = useState<boolean>(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const updateWindowWidth = () => {
            setTimeout(() => {
              setWindowWidth(window.innerWidth);
            }, 400); 
          };
        
          window.addEventListener('resize', updateWindowWidth);
        
          return () => {
            window.removeEventListener('resize', updateWindowWidth);
          };
        }, []);

    return (
        <header className={classname}>
            <div className="header-top">
                <p>Программно-аппаратный комплекс</p>
                <p>Производитель интеллектуальных систем видеонаблюдения</p>
            </div>
            {windowWidth >= 900 ? (
                <div className="header-nav">
                    <Link to='/' className='logo'>
                        <img src={logo} alt="logotype" />
                    </Link>
                    <nav>
                        <ul>
                            <li>
                                <Link to='/' className="link">Главная</Link>
                            </li>
                            <li>
                                <Link to='/about' className="link">О нас</Link>
                            </li>
                            <li>
                                <Link to='/solutions' className="link">Решения</Link>
                            </li>
                            <li>
                                <Link to='/integration' className="link">Интеграция</Link>
                            </li>
                            <li>
                                <Link to='/contacts' className="link">Контакты</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            ) : (
                <div className="burger">
                    <div className="burger-text" onClick={() => setOpen(!isOpen)}>
                        <p >МЕНЮ</p>
                        <svg stroke="white" fill="white" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"></path></svg>
                    </div>
                    { isOpen && (<div className="burger-nav">
                        <ul>
                            <li>
                                <Link to='/' className="burger-link" onClick={() => setOpen(!isOpen)}>Главная</Link>
                            </li>
                            <li>
                                <Link to='/about' className="burger-link" onClick={() => setOpen(!isOpen)}>О нас</Link>
                            </li>
                            <li>
                                <Link to='/solutions' className="burger-link" onClick={() => setOpen(!isOpen)}>Решения</Link>
                            </li>
                            <li>
                                <Link to='/integration' className="burger-link" onClick={() => setOpen(!isOpen)}>Интеграция</Link>
                            </li>
                            <li>
                                <Link to='/contacts' className="burger-link" onClick={() => setOpen(!isOpen)}>Контакты</Link>
                            </li>
                        </ul>
                    </div>)

                    }

                </div>
            )
            }
         </header>       
    )
}

export default Header;