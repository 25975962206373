import Top from "../../components/Top/Top";
import './Competence.scss';

const Competence: React.FC = () => {
    return (
        <section className="competence">
            <Top 
                header="Наши компетенции"
                text="У вас есть проект?    У нас есть компетенции! "
                classParent="competence-top"
                text2="Пишите: info@ommatid.com"
            />
           
        </section>
    )
}

export default Competence;