export interface IPhotos {
    title: string
    name: string
    class: string
}

const data: IPhotos[] = [
    {
        title: 'МУЛЬТИМАТРИЧНАЯ СИСТЕМА ВЫСОКОГО РАЗРЕШЕНИЯ',
        name: 'Ommatid Polycamera',
        class: 'polycamera'
    },
    {
        title: 'СЕТЕВОЙ МНОГОКАНАЛЬНЫЙ ВИДЕОРЕГИСТРАТОР',
        name: 'Ommatid RECORDER',
        class: 'recorder'
    }
]

export default data;