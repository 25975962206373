import vision from '../../../assets/images/about-vision.jpg';
import logo from '../../../assets/images/about-vision-logo.png';
import './Vision.scss';

const Vision: React.FC = () => {
    return (
        <div className="container">
            <section className="vision">
                <img src={vision} alt="vision" className='vision-main-image'/>
                <div className="vision-texts">
                    <h2 className="vision-header">ВИДЕНИЕ:</h2>
                    <p className="vision-text">В 2025 году наша компания будет приглашена для участия в крупнейшие и значимые проекты в сфере инженерии и BIM  3D проектирования. Наш бренд и наше производство систем специализированного видеонаблюдения и распознавания лиц узнаваемы, а продукция - востребована на известнейших Аэропортах, Стадионах, Вокзалах, городских площадях и на крупных массовых мероприятиях.</p>
                    <img src={logo} alt="logotype" className="vision-img" />
                </div>
            </section>
        </div>
    )
}

export default Vision;