import { IList } from "./List";

interface ICatalogProps {
    offers: IList[]
    selectedProduct: IList
    onSelect: (product: IList) => void
}

const Catalog: React.FC<ICatalogProps> = ({offers, selectedProduct, onSelect}) => {
    return (
        <div className="catalog-list">
            {offers.map((offer) => (
                <h3
                    key={offer.id}
                    className={`catalog-item ${offer.id === selectedProduct.id ? 'selected' : ''}`}
                    onClick={() => onSelect(offer)}>
                    {offer.name}
                </h3>
            ))}
        </div>
    )
}

export default Catalog;