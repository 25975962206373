import Top from "../../components/Top/Top";
import Progress from "./Progress/Progress";
import Vision from "./Vision/Vision";
import './About.scss';

const About: React.FC = () => {
    return (
        <section className="about">
            <Top 
                header="МИССИЯ:"
                text="Мы предлагаем свою уникальную компетенцию в сфере особых комплексных технических решений обеспечения безопасности в местах массового скопления людей. Мы отличаемся тем, что используем высокотехнологичные системы и новейшие средства для панорамного всеобъемлющего  видеонаблюдения на огромных территориях и значимых объектах городской инфраструктуры. Почувствовать себя в безопасности когда вокруг много людей - это так просто."
                btn="Написать"
                classParent="mission"
            />
            <Vision />
            <Progress />
        </section>
    )
}

export default About;