import './Grid.scss';

export interface IGrid {
    title: string
    text: string
    image: string
}

interface GridProps {
    data: IGrid[]
    classParent: string
    header?: string
}

const Grid: React.FC<GridProps> = ({data,header, classParent}) => {
    return (
        <div className="container">
            <section className={`${classParent} grids`}>
                {data.map((el, i) => (
                    <div key={i} className="grid">
                        <div className="grid-top">
                            <img src={el.image} alt="icon" />
                            <h2 className="grid-header">{el.title}</h2>
                        </div>
                        <p className="grid-text">{el.text}</p>
                    </div>
                ))}
            </section>
        </div>
    )
}

export default Grid;