import sports from '../../../assets/images/experience-sport.png';
import airports from '../../../assets/images/experience-airports.png';
import transport from '../../../assets/images/experience-transport.png';
import analytics from '../../../assets/images/experience-analytics.png';
import safeСity from '../../../assets/images/experience-safeCity.png';
import industry from '../../../assets/images/experience-industry.png';
import { IGrid } from "../../../components/Grid/Grid";


const experienceData: IGrid[] = [
    {
        title: 'Спортивные объекты',
        text: 'Обеспечение безопасности на трибунах спортивных объектов, с большой зоной покрытия из одной точки наблюдения.',
        image: sports
    },
    {
        title: 'Аэропорты',
        text: 'Наблюдение за взлетно посадочной полосой, зоной руления, погрузочным сектором. Распознавание бортовых номеров воздушного транспорта.',
        image: airports
    },
    {
        title: 'Транспортная отрасль',
        text: 'Контроль полос движения автомобильного транспорта из одной точки, с максимальным качеством.',
        image: transport
    },
    {
        title: 'Аналитика',
        text: 'Детектирование и распознавание лиц, классификация объектов, ситуационная аналитика.',
        image: analytics
    },
    {
        title: 'Безопасный город',
        text: 'Наблюдение за большим скоплением людей из одной точки наблюдения, в высоком разрешении с возможностью распознавания лиц.',
        image: safeСity
    },
    {
        title: 'Промышленный сектор',
        text: 'Контроль протяженных нефтеналивных ЖД линий, мониторинг промышленных площадок и производств.',
        image: industry
    }
]

export default experienceData;