import { Link } from 'react-router-dom';
import './Top.scss';

interface TopProps {
    header: string
    text: string
    btn?: string
    classParent: string
    text2?: string
}

const Top: React.FC<TopProps> = ({header, text, text2, btn, classParent}) => {
    return (
        <section className={`${classParent} top`}>
            <div className="top-img">
                <div className="container">
                    <div className="top-filling">
                        <h2 className="top-header">{header}</h2>
                        <p className="top-text">{text}</p>
                        {text2 ? <p className="top-text">{text2}</p> : <></>}
                        {btn? <Link to='/contacts' className="top-btn">{btn}</Link>: <></>}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Top;