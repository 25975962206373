import projects from '../../assets/images/advantage-projects.png';
import production from '../../assets/images/advantage-production.png';
import protection from '../../assets/images/advantage-protection.png';
import support from '../../assets/images/advantage-support.png';
import solution from '../../assets/images/advantage-solution.png';
import integration from '../../assets/images/advantage-integration.png';
import { IGrid } from '../../components/Grid/Grid';

const advantages: IGrid[] = [
    {
        title: 'Комплексные проекты',
        text: 'Мы можем обеспечить выполнение проекта начиная с проектирования и заканчивая монтажем и сдачей проекта заказчику.',
        image: projects
    },
    {
        title: 'Российское производство',
        text: 'Все наши решения разрабатываются и производятся на территории России, имеют сертификаты и статусы Российского производства.',
        image: production
    },
    {
        title: 'Регистрация проектов',
        text: 'Строго соблюдаем политику регистрации проектов.',
        image: protection
    },
    {
        title: 'Техническая поддержка',
        text: 'Предоставляем техническую поддержку на оборудование, консультации, а также обучение заказчиков.',
        image: support
    },
    {
        title: 'Индивидуальные решения',
        text: 'Доработка и релизация требований заказчика под определенные условия эксплуатации.',
        image: solution
    },
    {
        title: 'Интеграция',
        text: 'По желанию заказчика возможна интеграция нашего оборудования, в существующий рабочий цикл заказчика. ',
        image: integration
    }
]

export default advantages;