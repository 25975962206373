import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import logo from '../../../assets/images/about-vision-logo.png';
import './Map.scss';

const YandexMap: React.FC = () => {
    return (
        <section className="yandex">
            <div className="container">
                <div className="yandex-sticker">
                    <img src={logo} alt="logotype" className="yandex-sticker-logo" />
                    <p className="yandex-sticker-text">Разработка иновационных решений в области видеонаблюдения.</p>
                    <p className="yandex-sticker-text">email: info@ommatid.com</p>
                </div>
            </div>
            <div className="yandex-map">   
                <YMaps>
                    <div style={{ width: '100%', height: '100%'}}>
                        <Map
                            defaultState={{ center: [55.777267, 37.704727], zoom: 15 }}
                            width="100%"
                            height="100%"
                        >
                            <Placemark
                                geometry={[55.777267, 37.704727]}
                                options={{
                                    preset: 'islands#blueDotIcon',
                                }}
                            />
                        </Map>
                    </div>
                </YMaps>
            </div>
        </section>
    )
}

export default YandexMap;