import { useLocation } from 'react-router-dom';
import './Call.scss';
import { Link } from 'react-router-dom';

const Call: React.FC = () => {
    const location = useLocation();

    let classname: string = 'call '
    switch(location.pathname) {
        case '/contacts': classname += 'contacts'
        break;
        default: classname += 'other'
    }

    return (
        <section className={classname}>
            <div className="container">
                <div className="call-text">
                    <h2 className="call-header">Мы рады сотрудничеству и новым проектам</h2>
                    <Link to='/contacts' className="call-link">Связаться</Link>
                </div>
            </div>
        </section>
    )
}

export default Call;