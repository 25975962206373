import { useState } from "react"
import Catalog from "./Catalog";
import './List.scss';
import ListDetails from "./ListDetails";

export interface IList {
    id: number
    name: string
    title: string
    description: string[];
    file?: string
}

interface IListProps {
    data: IList[]
    classParent: string
}

const List: React.FC<IListProps> = ({data, classParent}) => {
    const [selectedProduct, setSelectedProduct] = useState<IList>(data[0]);

    const handleElSelect = (el: IList) => {
        setSelectedProduct(el)
    }

    return (
        <div className="container">
            <div className={`${classParent} list`}>
                <Catalog
                    offers={data}
                    selectedProduct={selectedProduct}
                    onSelect={handleElSelect}
                />
                <ListDetails 
                    el={selectedProduct}
                />
            </div>
        </div>
    )
}

export default List;