import { Link } from "react-router-dom";
import logo from '../../assets/images/logo-light.png';
import './Footer.scss';

const Footer: React.FC = () => {
    let year: number = (new Date()).getFullYear();
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-top">
                    <img src={logo} alt="logotype" />
                    <p>Мы создаем иновационные решения в области видеонаблюдения более 10 лет, которые помогают решать критически важные вопросы безопасности. </p>
                    <div className="socials">

                    </div>
                </div>
            </div>
            <div className="footer-nav">
                <div className="container">
                    <div className="footer-nav-nav">
                        <p className="copyright">Copyright © {year} | Все права защищены</p>
                        <nav>
                            <ul>
                                <li>
                                    <Link to='/' className="link">Главная</Link>
                                </li>
                                <li>
                                    <Link to='/about' className="link">О нас</Link>
                                </li>
                                <li>
                                    <Link to='/solutions' className="link">Решения</Link>
                                </li>
                                <li>
                                    <Link to='/integration' className="link">Интеграция</Link>
                                </li>
                                <li>
                                    <Link to='/contacts' className="link">Контакты</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;