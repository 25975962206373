import img1 from '../../../assets/images/progress-1.png';
import img2 from '../../../assets/images/progress-2.png';
import img3 from '../../../assets/images/progress-3.png';
import img4 from '../../../assets/images/progress-4.png';
import './Progress.scss';

const Progress: React.FC = () => {
    return (
        <section className="progresses">
            <div className="progresses-img">
                <div className="container">
                    <div className="progresses-filling">
                        <div className="progress">
                            <img src={img1} alt="" className="progress-img" />
                            <div className="progress-texts">
                                <h2 className="progress-header">Клиенты</h2>
                                <p className="progress-text-span">23</p>
                                <p className="progress-text">Выполненых проекта</p>
                            </div>

                        </div>
                        <div className="progress">
                            <img src={img2} alt="" className="progress-img" />
                            <div className="progress-texts">
                                <h2 className="progress-header">Значение</h2>
                                <p className="progress-text-span">75%</p>
                                <p className="progress-text">Описание</p>

                            </div>
                        </div>
                        <div className="progress">
                            <img src={img3} alt="" className="progress-img" />
                            <div className="progress-texts">
                                <h2 className="progress-header">Значение</h2>
                                <p className="progress-text-span">59</p>
                                <p className="progress-text">Описание</p>

                            </div>
                        </div>
                        <div className="progress">
                            <img src={img4} alt="" className="progress-img" />
                            <div className="progress-texts">
                                <h2 className="progress-header">Значение</h2>
                                <p className="progress-text-span">138</p>
                                <p className="progress-text">Описание</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Progress;