import { IList } from "./List";
import pdf from '../../assets/images/pdf.png'

interface IListDetailsProps {
  el: IList;
}

const ListDetails: React.FC<IListDetailsProps> = ({ el }) => {
  return (
    <div className="list-details">
      <h2 className="list-header">{el.title}</h2>
      {el.description.map((paragraph, index) => (
        <p key={index} className="list-description">{paragraph}</p>
      ))}
      {el.file && (
        <a
          href={el.file}
          target="_blank" rel="noreferrer"
          download
          className="link-to-pdf"
        >
          <img src={pdf} alt="icon pdf" />
          <p>Скачать файл с характеристиками устройства</p>
        </a>)}
    </div>
  );
};

export default ListDetails;