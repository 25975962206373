import Photo from './Photo/Photo';
import photos from './Photo/data';
import advantages from './advantages';
import Products from './Products/Products';
import Presentation from './Presentation/Presentation';
import Grid from '../../components/Grid/Grid';
import './Main.scss';

const Main: React.FC = () => {
    return (
        <section className="main">
            <Photo data={photos}/>
            <Grid 
                data={advantages}
                classParent='advantages'
            />
            <Products/>
            <Presentation />
        </section>
    )
}

export default Main;