import Grid from '../../../components/Grid/Grid';
import './Experience.scss';
import experienceData from './experienceData';

const Experience: React.FC = () => {
    return (
        <section className="container experiments">
           <h2 className='experiments-header'>Наша компания имеет большой опыт в реализации сложных проектов для различных отраслей бизнеса</h2>
            <Grid 
                data={experienceData}
                classParent='experiments'
            />
        </section>
    )
}

export default Experience;