export interface IOffer {
    id: number
    name: string
    title: string
    description: string[];
}

const offers: IOffer[] = [
    {
        id: 1,
        name: 'Что мы предлагаем',
        title: 'Что мы предлагаем',
        description: ['Разработка декстопных приложений',
        'Реализация MVP в кратчайшие сроки',
        'Создание веб сервисов',
        'Подписание NDA',
        'Полный цикл тестирования',
        'Внедрение продукта и техническая поддержка',
        'Гарантийные обязательства'
    ]
    },
    {
        id: 2,
        name: 'Этапы разработки',
        title: 'Этапы разработки',
        description: ['Формируем требования к продукту',
        'Создаем дизайн интерфейса',
        'Разработка',
        'Тестирование продукта и выпуск документации',
        'Внедрение и обучение заказчика',
        'Сопровождение и техническая поддержка'
    ]
    },
    {
        id: 3,
        name: 'Что Вы получаете',
        title: 'Что Вы получаете',
        description: ['Контроль разработки на всем этапе',
        'Возможность внесения изменений в процессе разработки',
        'Общение на языке заказчика',
        'Постоянный доступ к промежуточным версиям',
        'Отсутствие бюрократии'
    ]
    },
    {
        id: 4,
        name: 'Стек технологий',
        title: 'Стек технологий',
        description: ['Бэкэнд и API: C/C++, GoLang, Python',
        'Фронт: QT, React, Angular, Node,js, Vue.js',
        'Базы данных: Postgres, MongoDB',
        'Графика: Figma, Sketch, 3d max'
    ]
    },
    {
        id: 5,
        name: 'Преимущества',
        title: 'Преимущества',
        description: ['Разработка кроссплатформенный приложений',
        'Прототипирование интерфейсов',
        'Интеграция в существующие решений',
        'Более 15 лет опыта разработки',
        'Собственный штат разработчиков'
    ]
    },
    {
        id: 6,
        name: 'Команда',
        title: 'Команда',
        description: ['FullStack Senior разработчики - 3',
        'Frontend dev - 2',
        'TeamLead / Achitector - 1',
        'DevOPS - 1',
        'Тестировщик - 2',
        'Product Owner / Аналитик - 1'
    ]
    },
]

export default offers;